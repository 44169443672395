@use '@angular/material' as mat;

// @import 'styles/variables/aptservizi.scss';
@import '@angular/material/theming';
@include mat-core();

:root {
  --colore-background: #44c8f5;
  --colore-background-chiaro: #ededed;
  --colore-background-contrasto: #fafafa;
  --colore-background-toolbar: #206d8b; /* Qui devi mettere un valore fisso o utilizzare JS per modificare il valore a runtime */
  --colore-testo-toolbar: #e0f4f8; /* Qui devi mettere un valore fisso o utilizzare JS per modificare il valore a runtime */
  --colore-riferimento: #206d8b;
  --colore-bottone-disabilitato: #8ddcf7;
  --colore-testo: #444444;
  --colore-testo-bottoni: #ffffff;
  --colore-testo-chiaro: #ffffff;
  --colore-sfondo-testata-tabelle: #dddddd;
  --colore-testo-toggle: #444444;
  --colore-testo-toggle-checked: #ffffff;
  --colore-sfondo-nuvoletta-destra: #16ccf1;
  --colore-sfondo-nuvoletta-sinistra: #ffffff;
  --colore-testo-chat-destra: #ffffff;
  --colore-testo-chat-sinistra: #444444;
  --colore-testo-testata-tabella: #444444;
  --altezza-logo-cliente: 70px;
  --altezza-logo-secure: 70px;
  --colore-bordo-selettore-lingua: #44c8f5;
}

.speaker {
  fill: var(--colore-background); /* o qualsiasi altro colore che desideri */
}

// definisce $mat-blue
$mat-blue: mat.define-palette(mat.$blue-palette);
// definisce $mat-orange
$mat-orange: mat.define-palette(mat.$orange-palette);
// definisce $mat-red
$mat-red: mat.define-palette(mat.$red-palette);

// definisce theme uguale ad un theme di default di angular material
$theme: mat.define-light-theme(
  (
    primary: $mat-blue,
    accent: $mat-blue,
    warn: $mat-red,
    foreground: mat.$light-theme-foreground-palette,
    background: mat.$light-theme-background-palette,
  )
);

// definisce theme-alternate
$theme-alternate: mat.define-light-theme(
  (
    primary: $mat-blue,
    accent: $mat-blue,
    warn: $mat-red,
    foreground: mat.$light-theme-foreground-palette,
    background: mat.$light-theme-background-palette,
  )
);

// definisce $accent
$accent: mat.define-palette(mat.$blue-palette);

.mat-form-field-underline {
  background-color: #7d98a26b !important;
}
.mat-form-field-disabled .mat-form-field-underline {
  background-color: transparent !important;
}

/* Font intero sito */

body [class*=' mat-'],
body [class^='mat-'] {
  font-family: 'Hind', sans-serif;
}
body, html {
  overflow-x: hidden;
}

.padding-4 {
  padding: 4px;
}

.as-split-area {
  background: var(--colore-background-chiaro) !important;
}

/* Colore testo intero sito */
.alternate-theme .mat-card {
  color: var(--colore-testo) !important;
}

body .material-icons {
  font-family: 'Material Icons' !important;
}

/* toolbar HP */
.theme .mat-toolbar {
  //background: var(--colore-testo-toolbar)!important; !important;
  background-color: var(--colore-background-toolbar) !important;
  color: var(--colore-testo-toolbar) !important;
}

.theme .mat-toolbar .mat-icon.mat-accent {
  color: var(--colore-testo-chiaro) !important;
}

.theme .mat-mini-fab.mat-accent,
.theme .mat-raised-button.mat-accent {
  color: var(--colore-testo-chiaro) !important;
}

.theme .mat-fab:not([class*='mat-elevation-z']),
.theme .mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

/* PAGINE INTERNE */

.form-segnalazione {
  padding: 24px;
  background: var(--colore-background-chiaro);
}

.mat-dialog-title {
  font-family: 'Montserrat' !important;
  font-size: 40px;
  font-weight: 800 !important;
  color: var(--colore-testo) !important;
  text-transform: uppercase;
  padding: 0px 0px 20px;
}

.mat-dialog-container {
  padding: 48px 24px !important;
}

#SchedaSegnalazione {
  padding: 24px 0px;
  border-radius: 24px;
}

.alternate-theme .mat-expansion-panel-header-title {
  color: var(--colore-testo) !important;
  font-family: montserrat;
  font-weight: 800;
}

/* COLORE DI BASE */
.theme .mat-fab.mat-accent,
.theme .mat-flat-button.mat-accent,
.theme .mat-mini-fab.mat-accent,
.theme .mat-raised-button.mat-accent {
  background-color: var(--colore-background) !important;
  color: var(--colore-testo-chiaro) !important;
}

.alternate-theme .mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

.alternate-theme .mat-icon.mat-accent {
  color: var(--colore-background);
}

.alternate-theme .mat-expansion-panel {
  color: var(--colore-testo) !important;
}

.alternate-theme .mat-input-element {
  caret-color: var(--colore-background);
  caret-color: var(--colore-testo) !important;
}

.accent {
  color: var(--colore-background) !important;
}

.mat-tooltip {
  /* your own custom styles here */
  /* e.g. */
  color: var(--colore-testo-chiaro) !important;
  background: var(--colore-background) !important;
}

.mat-button-toggle {
  background-color: var(--colore-background-chiaro) !important;
  color: var(--colore-testo-toggle) !important;
}

.mat-button-toggle-checked {
  background-color: var(--colore-background) !important;
  color: var(--colore-testo-bottoni) !important;
}

.theme .mat-dialog-container .mat-button {
  color: var(--colore-testo-bottoni) !important;
  background: var(--colore-background) !important;
  margin-top: 24px;
}

.alternate-theme .mat-fab.mat-accent.mat-button-disabled,
.alternate-theme .mat-fab.mat-button-disabled.mat-button-disabled,
.alternate-theme .mat-fab.mat-primary.mat-button-disabled,
.alternate-theme .mat-fab.mat-warn.mat-button-disabled,
.alternate-theme .mat-flat-button.mat-accent.mat-button-disabled,
.alternate-theme .mat-flat-button.mat-button-disabled.mat-button-disabled,
.alternate-theme .mat-flat-button.mat-primary.mat-button-disabled,
.alternate-theme .mat-flat-button.mat-warn.mat-button-disabled,
.alternate-theme .mat-mini-fab.mat-accent.mat-button-disabled,
.alternate-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled,
.alternate-theme .mat-mini-fab.mat-primary.mat-button-disabled,
.alternate-theme .mat-mini-fab.mat-warn.mat-button-disabled,
.alternate-theme .mat-raised-button.mat-accent.mat-button-disabled,
.alternate-theme .mat-raised-button.mat-button-disabled.mat-button-disabled,
.alternate-theme .mat-raised-button.mat-primary.mat-button-disabled,
.alternate-theme .mat-raised-button.mat-warn.mat-button-disabled {
  background-color: #ebebeb !important;
  border-color: #dbdbdb !important;
  border: solid 1px;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  color: #dbdbdb !important;
}

.alternate-theme .mat-form-field-ripple {
  background-color: var(--colore-background) !important;
}

.alternate-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: var(--colore-background);
}

.alternate-theme .mat-input-element {
  caret-color: var(--colore-testo) !important;
}

theme .mat-button.mat-accent,
.theme .mat-icon-button.mat-accent,
.theme .mat-stroked-button.mat-accent {
  color: var(--colore-background);
}

.alternate-theme .mat-dialog-container .mat-button,
.alternate-theme .mat-icon-button,
.alternate-theme .mat-stroked-button {
  color: var(--colore-background) !important;
}

.alternate-theme .mat-icon.mat-primary {
  color: var(--colore-background) !important;
}

.alternate-theme .mat-select-value {
  color: var(--colore-testo) !important;
}

.theme .mat-option {
  color: var(--colore-testo) !important;
}

/*
.alternate-theme .mat-button, .alternate-theme .mat-icon-button, .alternate-theme .mat-stroked-button {
  color: var(--colore-testo-chiaro) !important;
  background: var(--colore-background)  !important;
}
*/

.theme .mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

  color: var(--colore-testo) !important;
  border-radius: 24px;
}

/* SFONDO SELECT */
.theme .mat-option.mat-active {
  background: var(--colore-testo-chiaro) !important;
  color: var(--colore-testo) !important;
}

.theme .mat-select-panel {
  background: var(--colore-testo-chiaro) !important;
}

.theme .mat-autocomplete-panel {
  background: var(--colore-testo-chiaro) !important;
}

/* SFONDO CHECKBOX */
.alternate-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.alternate-theme
  .mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background {
  background-color: var(--colore-background);
}

/* COLORE DELLE LABEL */
.alternate-theme .mat-form-field-appearance-legacy .mat-form-field-label,
.alternate-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #999 !important;
}

.alternate-theme .mat-paginator {
  background: none;
}

.alternate-theme .mat-paginator,
.alternate-theme .mat-paginator-page-size .mat-select-trigger {
  color: var(--colore-testo) !important;
}

.alternate-theme .mat-header-cell {
  color: var(--colore-testo) !important;
}

/*POSIZIONE MANUALE */
.link-manuale {
  padding: 0px 24px;
}

/* CHAT */
app-chat-manager .alternate-theme {
  margin: 48px !important;
}

app-chat-manager .mat-drawer-content {
  background: var(--colore-background-chiaro) !important;
}

app-chat-manager .footer {
  color: var(--colore-testo) !important;
  background-color: #ccc;
  padding: 12px !important;
}

app-chat-manager .footer input.mat-input-element {
  margin-top: 3px !important;
  border-radius: 24px !important;
  border: solid 1px #a1a1a1 !important;
  background: var(--colore-testo-chiaro) !important;
}

.theme .footer .mat-icon.mat-accent {
  color: var(--colore-testo-chiaro) !important;
}

.theme .footer .mat-button,
.theme .footer .mat-icon-button,
.theme .footer .mat-stroked-button {
  color: inherit;
  background: var(--colore-background) !important;
  padding: 0px !important;
  margin: 2px 22px -2px 8px !important;
  border-radius: 18px !important;
  min-width: 38px !important;
}

.theme .footer .mat-form-field-prefix .mat-icon.mat-accent {
  color: var(--colore-testo) !important;
}

.footer .ng-star-inserted {
  max-width: 100px !important;
  // min-width: 51px !important;
}

.footer .mat-form-field-prefix,
.footer .mat-form-field-suffix {
  background: var(--colore-testo-chiaro) !important;
  margin: 0px !important;
  padding: 9px 3px 6px 9px !important;
  border-radius: 24px !important;
  min-width: 27px !important;
  border: solid 1px var(--colore-background) !important;
}

/* ADMIN */

.theme .mat-table,
.theme .mat-table-sticky,
.theme .mat-table tbody,
.theme .mat-table tfoot,
.theme .mat-table thead,
.theme [mat-footer-row],
.theme [mat-header-row],
.theme [mat-row],
.theme mat-footer-row,
.theme mat-header-row,
.theme mat-row {
  background: var(--colore-testo-chiaro) !important;
  color: var(--colore-testo) !important;
}

.theme .mat-paginator {
  background: var(--colore-testo-chiaro) !important;
  color: var(--colore-testo) !important;
}

.theme .mat-header-cell {
  color: var(--colore-testo) !important;
  line-height: 21px;
}

.theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: var(--colore-testo-chiaro) !important;
  opacity: 1 !important;
}

.theme mat-row.pointer {
  background: var(--colore-testo-chiaro) !important;
  color: var(--colore-testo) !important;
  border-bottom: solid 1px var(--colore-background);
  padding: 10px;
}

.theme .mat-header-cell {
  color: var(--colore-testo) !important;
  line-height: 21px;
  border-bottom: solid 2px var(--colore-background);
}

.theme .mat-button,
.theme .mat-icon-button,
.theme .mat-stroked-button {
  color: var(--colore-background) !important;
}

.theme .mat-cell,
.theme .mat-footer-cell {
  color: var(--colore-testo) !important;
}

.theme mat-row:hover mat-cell {
  color: var(--colore-background) !important;
  font-weight: 900 !important;
}

.theme
  .mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after,
.theme .mat-input-element:disabled {
  color: var(--colore-testo) !important;
}

.theme .mat-input-element {
  caret-color: var(--colore-background);
}

.sezione-periodo,
.titolo-grafico {
  color: var(--colore-testo) !important;
  padding-left: 12px;
}

.titolo-grafico {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 800;
  color: var(--colore-testo) !important;
  text-align: left;
  text-transform: uppercase;
  padding: 0px;
}

.sezione-periodo[_ngcontent-luh-c264],
h1.titolo-grafico[_ngcontent-luh-c264] {
  color: var(--colore-testo) !important;
}

.alternate-theme .mat-tab-label,
.alternate-theme .mat-tab-label.mat-tab-disabled,
.alternate-theme .mat-tab-link,
.alternate-theme .mat-tab-link.mat-tab-disabled {
  color: var(--colore-testo) !important;
}

.alternate-theme .mat-list-base .mat-list-item,
.alternate-theme .mat-list-base .mat-list-option {
  color: var(--colore-testo) !important;
}

.titolo-sezione {
  width: 100%;
  color: var(--colore-testo) !important;
  font-family: 'Montserrat' !important;
  text-transform: uppercase !important;
  text-align: left !important;
  padding: 24px 24px 48px 24px;
  font-weight: 800 !important;
}

.alternate-theme .mat-drawer-container {
  background-color: var(--colore-background-chiaro) !important;
  color: var(--colore-testo) !important;
}

.titolo-sezioni-landing-34 {
  font-size: 40px;
  line-height: 40px;
}

.testo-presentazione-landing-hind {
  text-align: left;
}

.titolo-sezioni-landing {
  font-size: 24px;
  line-height: 24px;
}

.testo-normale-landing {
  line-height: 18px;
}

.domanda[_ngcontent-vte-c305] {
  font-family: Hind;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: var(--colore-testo-chiaro);
  text-align: right;
  padding: 10px 5px;
  margin: 0px !important;
}

@media only screen and (max-width: 5000px) {
  mat-card {
    padding: 36px !important;
    border-radius: 24px !important;
  }
  .titolo-pagina {
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 800;
    color: var(--colore-testo) !important;
    text-align: left;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 600px) {
  .altezza-toolbar-landing {
    height: 200px !important;
  }
  mat-card {
    padding: 0px !important;
  }
  .mat-paginator-container {
    margin-top: 24px;
  }
  .mat-expansion-panel-body {
    padding: 5px !important;
  }
  .titolo-pagina {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 800;
    color: var(--colore-testo) !important;
    text-align: left;
    text-transform: uppercase;
  }
}

/////////////////////////////////////////////////////////////////
///
///
///      Alcune customizzazioni di vari componenti
///
///
/////////////////////////////////////////////////////////////////

@mixin mat-toolbar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-toolbar {
    background: mat-color($background, app-bar);
    color: mat-color($foreground, app-bar-text);

    &.mat-primary {
      @include _mat-toolbar-color($primary);
    }

    &.mat-accent {
      @include _mat-toolbar-color($accent);
    }

    &.mat-warn {
      @include _mat-toolbar-color($warn);
    }

    @include _mat-toolbar-form-field-overrides;
  }
}

@mixin mat-table-theme($my-theme) {
  $background: map-get($my-theme, background);
  $foreground: map-get($my-theme, foreground);

  .mat-table {
    background: mat-color($background, 'table');
  }

  .mat-table thead,
  .mat-table tbody,
  .mat-table tfoot,
  mat-header-row,
  mat-row,
  mat-footer-row,
  [mat-header-row],
  [mat-row],
  [mat-footer-row],
  .mat-table-sticky {
    background: mat-color($background, 'table');
  }

  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-color: mat-color($foreground, divider);
  }

  .mat-header-cell {
    color: mat-color($foreground, secondary-text);
  }

  .mat-cell,
  .mat-footer-cell {
    color: mat-color($foreground, table-text);
  }

  mat-row:nth-child(n):hover {
    background-color: mat-color($background, row-hover);
  }

  mat-row:hover mat-cell {
    color: mat-color($foreground, row-hover);
    font-weight: bolder !important;
  }
  .mat-row:nth-child(2n + 1) {
    background-color: mat-color($background, odd-row);
  }

  .mat-row:nth-child(2n) {
    background-color: mat-color($background, even-row);
  }

  .mat-row:nth-child(4n) {
    background-color: mat-color($background, odd-row);
  }

  .mat-row:nth-child(4n + 1) {
    background-color: mat-color($background, even-row);
  }
}

// Alcune modifiche alla sezione di paginazione
@mixin mat-paginator-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-paginator {
    background: mat-color($background, 'table');
  }

  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    color: mat-color($foreground, secondary-text);
  }

  .mat-paginator-decrement,
  .mat-paginator-increment {
    border-top: 2px solid mat-color($foreground, 'icon');
    border-right: 2px solid mat-color($foreground, 'icon');
  }

  .mat-paginator-first,
  .mat-paginator-last {
    border-top: 2px solid mat-color($foreground, 'icon');
  }

  .mat-icon-button[disabled] {
    .mat-paginator-decrement,
    .mat-paginator-increment,
    .mat-paginator-first,
    .mat-paginator-last {
      border-color: mat-color($foreground, 'disabled');
    }
  }
  mat-paginator .mat-select-value {
    color: mat-color($foreground, secondary-text) !important;
  }
}

/*///////////////////////////////////////////////////////////////////////////////
//
//   Modifica per avere lo sfondo del drawer dello stesso colore della toolbar
//
//
*/ ///////////////////////////////////////////////////////////////////////////////
@mixin toolbar-color($palette) {
  background: mat-color($palette);
  color: mat-color($palette, default-contrast);
}

@mixin sidenav-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-drawer {
    // let's take mat-toolbar's default theme
    // ref: https://github.com/angular/material2/blob/88601fa51d581f538468a9b63890b0e285e519cb/src/lib/toolbar/_toolbar-theme.scss#L39
    background-color: mat-color($background, background);
    color: mat-color($foreground, text);

    // now, mat-toolbar's colored themes
    &[color='primary'] {
      @include toolbar-color($primary);
    }
    &[color='accent'] {
      @include toolbar-color($accent);
    }
    &[color='warn'] {
      @include toolbar-color($warn);
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
//
//      SEZIONE UTILE PER CONFIGURARE I VARI TEMI ANCHE PER il DOMINIO dae
//      ALTRIMENTI IL SISTEMA DI CARICAMENTO OPERA SOLO SUL DOMINIO DI PRODUZIONE
//      E' PRATICAMENTE UNA COPIA DELLE ANALOGHE DICHIARAZIONI FATTE PER IL
//      DOMINIO DI PRODUZIONE
//
//
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
.theme {
  @include mat.all-component-themes($theme);
  @include sidenav-component-theme($theme);
}

.alternate-theme {
  @include angular-material-theme($theme-alternate);
}

//////////////////////////////////////////////////////////////////////////

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  // margin-bottom: 24px;
  // overflow-x: hidden;
  caret-color: transparent;
}

.angular-editor-textarea {
  caret-color: black;
}
html {
  height: calc(100% - 90px);
}

.mat-dialog-title {
  font-family: 'Montserrat' !important;
  font-size: 40px;
  font-weight: 800 !important;
  color: var(--colore-testo) !important;
  text-transform: uppercase;
  padding: 0px 0px 20px;
}

.mat-dialog-container {
  padding: 48px 24px !important;
  height: auto !important;
}

.allega {
  font-size: 15px;
}

body .material-icons {
  font-family: 'Material Icons';
}

.material-icons {
  vertical-align: middle;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.centra-testo {
  text-align: center !important;
  vertical-align: middle;
  line-height: 60px;
}

.chat-label-oggetto-segnalazione {
  position: relative;
  font-size: small;
  top: -60px;
  text-align: center;
  vertical-align: middle;
  // color: var(--colore-testo)-testata-card-livello-1;
}

.center {
  width: 50%;
  /* position: relative; */
  left: 50%;
  /* margin-right: 50%; */
  transform: translate(-50%, 0);
}

.titolo-dialog {
  position: relative;
  width: 100%;
  text-align: center;
}

.fischietto-piccolo {
  max-width: 64px;
}

.pointer {
  cursor: pointer;
}

.height-full {
  height: 99%;
}

.width-full {
  width: 100% !important;
}

.width-150px {
  width: 150px;
}

.margine-sinistro {
  margin-left: 18px;
}

.lista-configurazione .mat-list-item {
  height: auto !important;
}

.width-300px {
  width: 300px;
}

.width-half {
  width: 100%;
}

.width-half-start {
  width: 30% !important;
}

.width-half-end {
  width: 19% !important;
}

.width-third {
  width: 31%;
}

.min-width {
  min-width: 300px;
}

.scheda-full-width {
  margin-top: 10px !important;
  display: inline-block;
  width: 100%;
}

.scheda-side-nav {
  width: auto;
  margin-right: 9px !important;
}

.top {
  top: 0px;
}

.top-10px {
  top: 10px;
}

.margin-top-20px {
  margin-top: 20px;
}

.centered {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.top-0px {
  top: 0px;
}

.top-13px {
  top: 13px;
}

.top-16px {
  top: 16px;
}

.margin-top-20px {
  margin-top: 20px;
}

.top-40px {
  top: 40px;
}

.top-64px {
  top: 64px;
}

.top-74px {
  top: 74px;
}

.top-50px {
  margin-top: 50px;
}

.bottom-30px {
  margin-bottom: 30px;
}

.top-61px {
  top: 61px;
}

.top-160px {
  top: 160px;
}

.top-95px {
  top: 95px;
}

.top-0px {
  top: 0px;
}

.top-58px {
  top: 58px;
}

.top-60px {
  margin-top: 60px;
}

.top-20px {
  margin-top: 20px;
}

.top-32px {
  top: 32px;
}

.top-136px {
  top: 136px;
}

.top-156px {
  top: 156px;
}

.padding-top-44px {
  padding-top: 44px;
}

.padding-top-64px {
  padding-top: 64px;
}

.center-20 {
  width: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 20px;
}

.center-40 {
  width: 40%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 20px;
}

.center-60 {
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 20px;
}

.sidebar-colonna-1 {
  z-index: 5;
  position: fixed;
  top: 10px;
  right: 2%;
}

.bottone-flottante-basso-destra {
  z-index: 5;
  position: fixed;
  bottom: 10px;
  right: 2%;
}

.bottone-flottante-basso-sinistra {
  z-index: 5;
  position: fixed;
  bottom: 10px;
  left: 20px;
}

.posizione-avviso-modalita-omissis {
  z-index: 5;
  position: fixed;
  bottom: 10px;
  left: 20px;
}

.posizione-flottante-alto-sinistra {
  z-index: 5;
  position: absolute;
  top: 70px;
  right: 50%;
}

.bottone-flottante-basso-destra-su {
  z-index: 5;
  position: fixed;
  bottom: 80px;
  right: 2%;
}

.bottone-flottante-basso-destra-su-su {
  z-index: 5;
  position: fixed;
  bottom: 160px;
  right: 2%;
}

.bottone-flottante-basso-destra-su-su-su {
  z-index: 5;
  position: fixed;
  bottom: 240px;
  right: 2%;
}

.bottone-flottante-basso-destra-su-su-su-su {
  z-index: 5;
  position: fixed !important;
  bottom: 280px;
  right: 2%;
}

.bottone-flottante-alto-sinistra {
  z-index: 5;
  position: fixed !important;
  top: 12px;
  left: 10px;
}

.bottone-flottante-alto-sinistra-basso {
  z-index: 5;
  position: fixed !important;
  top: 76px;
  left: 10px;
}

.bottone-flottante-alto-sinistra-basso-basso-col1 {
  z-index: 5;
  position: fixed !important;
  top: 210px;
  left: 40px;
}

.bottone-flottante-alto-sinistra-basso-basso-col2 {
  z-index: 5;
  position: fixed !important;
  top: 210px;
  left: 120px;
}

.bottone-flottante-alto-sinistra-basso-basso-col3 {
  z-index: 5;
  position: fixed !important;
  top: 210px;
  left: 200px;
}

.bottone-flottante-alto-sinistra-basso-basso-col4 {
  z-index: 5;
  position: fixed !important;
  top: 210px;
  left: 280;
}

.bottone-flottante-alto-sinistra-basso-basso-col5 {
  z-index: 5;
  position: fixed !important;
  top: 210px;
  left: 360px;
}

.bottone-flottante-alto-sinistra-basso-basso-col6 {
  z-index: 5;
  position: fixed !important;
  top: 210px;
  left: 440px;
}

.selettore-gruppo-gestore {
  z-index: 5;
  position: absolute !important;
  top: 20px;
  left: 200px;
  width: auto;
}

.height-auto {
  height: auto !important;
}

.accent {
  color: map_get($accent, 500) !important;
}
.destra {
  float: right;
}

.sinistra {
  float: left;
}

.position-fixed {
  position: fixed !important;
}

.position-relative {
  position: relative !important;
}

.posizione-errori-password {
  position: relative;
  top: -10px;
}

.errori-form {
  font-size: 0.9em;
}

.testo-piccolo {
  font-size: 0.8em;
}
.testo-piccolo {
  font-size: small;
}

.testo-medio {
  font-size: medium;
}

.testo-grande {
  font-size: xx-large;
  // color: var(--colore-testo)-testata-card-livello-1;
}

.testo-large {
  font-size: large;
}

.testo-x-large {
  font-size: x-large;
}

.testo-xx-large {
  font-size: xx-large;
}

/*
  Icona del floating action button
*/
.icona-fab {
  // color: $colore-icona-fab;
  font-size: 28px !important;
  display: block !important;
  line-height: inherit !important;
  width: inherit !important;
}

.icona-fab-mini {
  // color: $colore-icona-fab;
  font-size: large !important;
  display: block !important;
  line-height: inherit !important;
  width: inherit !important;
}

.sfondo-giallo-brillante {
  background-color: rgb(255, 255, 144) !important;
  color: rgba(0, 0, 0, 0.8);
}
.sfondo-giallo {
  background-color: rgba(255, 255, 0, 1) !important;
  color: rgba(0, 0, 0, 0.8);
}

.sfondo-giallo-trasparente {
  background-color: rgba(255, 255, 0, 0.5) !important;
  color: rgba(0, 0, 0, 0.8);
}

.padding-20 {
  padding: 20px;
}

.padding-5 {
  padding: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.input-corti {
  flex-basis: 10px !important;
}

.mat-fab {
  z-index: 100 !important;
}

.relative {
  position: relative;
}

.mat-card-login {
  // background-color: var(--colore-background)-login !important;
  padding: 36px !important;
}

.bottone-bordato {
  // border-color: $colore-principale !important;
  border-width: thin !important;
  border-style: solid !important;
  border-radius: 0em !important;
}

.bottone-login {
  // color: var(--colore-testo)-testata-pagina;
  font-size: large;
}

.link {
  cursor: pointer;
  font-size: 1em;
  // color: rgba(10, 10, 10, 0.6);
  text-decoration-line: underline;
}

.icone-barra-principale {
  // color: $colore-icone-testata-pagina !important;
  font-size: 36px;
  width: 36px !important;
  color: var(--colore-testo-toolbar) !important;
}

.icone-in-mezzo-al-testo {
  // color: $colore-icone-testata-pagina !important;
  font-size: 24px;
  width: 24px !important;
}

/**
  colore testo testata tabelle
*/
// .mat-table .mat-header-row .mat-header-cell {
//   // color: var(--colore-testo)-testata-tabella;
//   font-weight: normal !important;
// }

.filtro-attivo .mat-sort-header-content {
  text-decoration: underline;
}

.filtro-disattivo .mat-sort-header-content {
  text-decoration: none;
}

.ricerca-in-corso .mat-sort-header-content {
  // testo sottolineato
  font-weight: 1000 !important;
}

.mat-sort-header-content {
  font-weight: 500 !important;
}

.mat-sort-header-button {
  font-weight: bold !important;
  font-size: large !important;
}

.icone-testata-tabelle {
  // color: $colore-icone-testata-tabella !important;
  font-size: xx-large !important;
  width: 36px !important;
  color: var(--colore-testo-testata-tabella) !important;
}

/*
  righe tabelle
*/
//.mat-table .mat-row .mat-cell {
// color: var(--colore-testo)-riga-tabella !important;
//}

.icona-riga-tabella {
  // color: $colore-icone-riga-tabella;
  font-size: xx-large !important;
  width: 36px !important;
  height: auto !important;
  color: var(--colore-riferimento);
}

.titolo-pagina {
  font-size: xx-large;
  width: 100%;
  text-align: center;
  font-weight: bold;
  align-self: center !important;
}

.padding-10 {
  padding: 10px;
}

.inoltra-segnalazione {
  position: absolute;
  text-align: right;
  right: 24px;
}
.left-pad {
  padding-left: 2px !important;
}

.mat-table .mat-header-row .mat-header-cell {
  background: var(--colore-sfondo-testata-tabelle) !important;
  color: var(--colore-testo-testata-tabella) !important;
}

.mat-expansion-panel-header {
  min-height: 40px !important;
}

.mat-expansion-panel-header:hover {
  min-height: 40px !important;
}

.mat-header-row {
  height: 60px;
}

.mat-row {
  min-height: 42px !important;
}

.mat-expansion-panel-header-title {
  // color: var(--colore-testo)-testata-card-livello-1 !important;
  font-size: x-large;
  z-index: 1000;
}

.mat-expansion-panel-header-title:hover {
  // color: var(--colore-testo)-testata-card-livello-1 !important;
  font-size: x-large;
  z-index: 1000;
}

.mat-expansion-panel-content {
  line-height: 30px !important;
}

.sottotestata-principale-dettagli-segnalazione {
  // background-color: var(--colore-background)-card-livello-2 !important;
  // border-color: $colore-bordo-testata-tabella !important;
  border-width: thin !important;
  border-style: hidden;
  border-radius: 0em !important;
  // color: var(--colore-testo)-card-livello-2;
}

.icone-sottotestata-principale-dettagli-segnalazione {
  // color: $colore-icone-testata-card-livello-2 !important;
  font-size: xx-large !important;
}

.mat-fab {
  // background-color: var(--colore-background)-fab !important;
  // color: $colore-icona-fab !important;
  font-size: xx-large !important;
}

.testata-chat {
  // background-color: var(--colore-background)-testata-card-livello-1;
  // border-color: var(--colore-background)-card-livello-1 !important;
  border-width: thin !important;
  border-style: solid !important;
  border-radius: 0em !important;
  // color: var(--colore-testo)-card-livello-1;
}

.icone-testata-chat {
  // color: $colore-icone-card-livello-1;
  font-size: xx-large !important;
}

.fumetto-chat {
  // background-color: var(--colore-background)-fumetto;
  // border-color: var(--colore-background)-card-livello-1 !important;
  border-width: thin !important;
  border-style: solid !important;
  border-radius: 0em !important;
  // color: var(--colore-testo)-card-livello-1;
}

.inserimento-messaggio-chat {
  // background-color: var(--colore-background)-messaggio-chat;
  // border-color: var(--colore-background)-card-livello-1 !important;
  border-width: thin !important;
  border-style: solid !important;
  border-radius: 0em !important;
  // color: var(--colore-testo)-card-livello-1;
}

.colore-testo-testata-pagina {
  // color: var(--colore-testo)-testata-pagina !important;
  // caret-color: var(--colore-testo)-testata-pagina !important;
  caret-color: rgba(0, 0, 0, 0.63);
}

// mat-label {
//   // color: $colore-titolo-campi !important;
//   font-size: larger !important;
// }

.contenuto-segnalazione {
  // color: $colore-contenuto-campi !important;
  font-size: large !important;
}

.mat-form-field-label {
  // color: $colore-titolo-campi !important;
  font-size: 18px !important;
}

// Truncate text in tables with ellipsis
.no-wrap {
  .mat-header-cell,
  .mat-header-cell button,
  .mat-cell {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// .mat-button-toggle-checked > button > div {
//   color: map_get($md-accent, 500)!important;
//   // color: white!important;
// }

// .mat-fab > span > .mat-icon {
//   color: map_get($md-primary, 500)!important;
// }

.titolo-sezione {
  width: 100%;
  font-size: xx-large;
  position: relative;
  text-align: center;
  font-weight: bold;
  // color: map_get($md-primary, 500);
  // color: var(--colore-testo)-titolo-sezione;
}

.titolo-sezione-bis {
  font-size: xx-large;
  position: relative;
  text-align: center;
  font-weight: bold;
  top: 10px;
  // color: var(--colore-testo)-titolo-sezione;
}

// mat-footer-row,
// mat-header-row,
// mat-row,
// td.mat-cell,
// td.mat-footer-cell,
// th.mat-header-cell {
//   border-bottom-color: var(--colore-testo)-titolo-sezione;
// }

.grassetto {
  font-weight: bold;
}

.time-picker {
  border-style: none;
  border-bottom-width: 0.1px;
  border-bottom-style: solid;
  position: relative;
  border-spacing: 30px;
  padding-bottom: 3px;
  font-family: 'Hind', 'Montserrat', 'Segoe UI', 'Noto Sans JP', sans-serif;
  font-weight: 300;
  font-size: 18px;
  // color: rgb(150, 150, 150);
  // border-color: var(--colore-testo)-titolo-sezione !important;
}

.bottone-filtro-stati {
  float: right;
  top: 10px;
  z-index: 10;
  text-align: right;
  font-weight: bold;
  // color: var(--colore-testo)-titolo-sezione;
}

.bottone-full-testo-start {
  text-align: start !important;
  width: 100% !important;
}

/* .mat-chip-list-wrapper {
  flex-direction: column!important;
} */

input.mat-chip-input {
  width: 100% !important;
  margin: 4px;
  flex-grow: 1;
  flex-shrink: 0;
}

.mat-tab-label.mat-tab-label-active {
  font-size: x-large;
  opacity: 1;
  // color: grey;
}

.mat-tab-label {
  font-size: large;
  opacity: 1;
  // color: white;
}

mat-panel-title {
  z-index: 1 !important;
}

.print:last-child {
  page-break-after: auto !important;
}

/* .odv-scelto-da-segnalante {
  font-weight: bold !important;
  background-color: #e7ff21 !important;
  color: rgb(63, 63, 63) !important;
} */

@media print {
  .no-print * {
    display: none !important;
  }

  * {
    overflow: visible !important;
    // color:black!important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  textarea {
    min-height: 300px;
  }

  #descrizione {
    min-height: 500px;
  }

  button {
    display: none !important;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  #header,
  #menu,
  #sidenav,
  #sidebar {
    height: 1px;
    display: none;
  }

  mat-sidenav {
    height: 1px;
    display: none;
  }
}

.footer {
  color: var(--colore-testo-toolbar);
  background-color: var(--colore-background-toolbar);
  // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-family: 'Hind', 'Montserrat', 'Segoe UI', 'Noto Sans JP', sans-serif;
  font-size: 0.7em;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  opacity: 0.5;
}

.testo-footer {
  padding-left: 5px;
  font-family: 'Hind', 'Montserrat', 'Segoe UI', 'Noto Sans JP', sans-serif;
  font-size: 0.7em;
  color: var(--colore-testo-toolbar);
  opacity: 0;
}

.testo-footer:hover {
  opacity: 1;
}

.testo-powered {
  font-family: 'Hind', 'Montserrat', 'Segoe UI', 'Noto Sans JP', sans-serif;
  font-size: 14px;
  color: white !important;
  font-weight: 700 !important;
  text-decoration: none;
}

.centro {
  text-align: center;
}

.disabled-light {
  color: var(--colore-background) !important;
}

.custom-tooltip {
  z-index: 10;
  padding: 16px;
  position: absolute;
  margin-top: 24px;
  background-color: var(--colore-background);
  color: var(--colore-testo-bottoni);
  border-radius: 5px;
}

.margin-auto {
  margin: auto !important;
}

mat-dialog-container {
  background-color: white !important;
}

.giustificato {
  text-align: justify;
}

.colonne {
  -webkit-column-count: 2; /* Saf3, Chrome*/
  -webkit-column-gap: 4%; /* Saf3, Chrome*/
  -moz-column-count: 2; /* FF3.5+ */
  -moz-column-gap: 4%; /* FF3.5+ */
  column-count: 2; /* Opera 11+*/
  column-gap: 4%; /* Opera 11+*/
}

.bullet-arrow {
  font-size: 0.7em;
  vertical-align: middle !important;
  display: inline-flex !important;
  align-items: center !important;
}

.allinea-end {
  text-align: end;
}

.centra-verticale {
  display: flex;
  justify-content: center;
  align-items: center;
}

mat-row.riga-dettaglio-inner {
  min-height: 0px !important;
  background-color: var(--colore-background-chiaro) !important;
}

.inner-table {
  padding-bottom: 50px;
}

.height-120px {
  height: 120px;
}

.colore-testo-risposte-custode {
  color: map_get($mat-orange, 700);
}

.colore-testo-avviso {
  color: map_get($mat-orange, 700);
}

.hidden {
  visibility: hidden;
}

.spacer {
  flex: 1 1 auto;
}

//  necessario per risolvere un bug in produzione non rilevabile in sviluppo
//  in pratica l'altezza delle combo dell'editor avanzato è esagerata
//  perchè viene posta al 100%
.ae-font .ae-picker-label {
  height: auto !important;
}

.grassetto {
  font-weight: bold;
}

mat-sidenav-content {
  overflow-x: hidden !important;
}

.link-toolbar {
  font-weight: 1000 !important;
  font-size: 16px;
  color: white !important;
}

.no-border {
  box-shadow: none !important;
  border: none;
}

.material-icons-outlined,
.material-icons.material-icons--outlined,
.material-icons-two-tone,
.material-icons.material-icons--two-tone,
.material-icons-round,
.material-icons.material-icons--round,
.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons-two-tone,
.material-icons.material-icons--two-tone {
  font-family: 'Material Icons Two Tone';
}

.material-icons-round,
.material-icons.material-icons--round {
  font-family: 'Material Icons Round';
}

.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-family: 'Material Icons Sharp';
}

.alternate-theme .mat-chip.mat-standard-chip.odv-predefinito {
  background-color: yellowgreen !important;
  color: var(--colore-testo-toolbar);
  border: solid 1px var(--colore-background);
  font-size: 12px;
  line-height: 14px;
}

.alternate-theme .mat-chip.mat-standard-chip.odv-alternativo {
  background-color: blue !important;
  color: var(--colore-testo-toolbar);
  border: solid 1px var(--colore-background);
  font-size: 12px;
  line-height: 14px;
}

.alternate-theme .mat-chip.mat-standard-chip.enabled-omissis {
  background-color: var(--colore-testo) !important;
  color: var(--colore-background) !important;
}
.alternate-theme .mat-chip.mat-standard-chip.disabled-omissis {
  background-color: var(--colore-background-chiaro) !important;
  color: var(--colore-testo) !important;
}

.alternate-theme .mat-chip.mat-standard-chip.conflitto-interessi {
  background-color: var(--colore-background-chiaro) !important;
  color: var(--colore-testo) !important;
  text-decoration: line-through !important;
  text-decoration-style: double !important;
}

.alternate-theme .mat-chip.mat-standard-chip.condivisione-sospesa {
  background-color: var(--colore-background-chiaro) !important;
  color: var(--colore-testo) !important;
  border: solid 1px var(--colore-background-chiaro);
  font-size: 12px;
  line-height: 14px;
}

.alternate-theme .mat-chip.mat-standard-chip {
  background-color: var(--colore-riferimento) !important;
  color: #f2f2f2 !important;
  border: solid 1px var(--colore-background);
  font-size: 12px;
  line-height: 14px;
}

.testo-accent {
  color: var(--colore-background) !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px !important;
}

.odv-predefinito {
  background-color: yellowgreen !important;
}

.odv-alternativo {
  background-color: blue !important;
  color: #eeee !important;
}

mat-header-cell,
mat-cell {
  justify-content: center;
}

.mat-drawer-content {
  height: auto !important;
  overflow-x: hidden !important;
}

.overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* Grigio trasparente */
  z-index: 9999; /* Assegna una profondità per farlo sovrapporre al contenuto della pagina */
}

.mini-padding {
  padding: 5px !important;
}

.flex-row {
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  display: flex;
}

.flex-0-row {
  justify-content: space-between;
  flex: 0;
  flex-direction: row;
  display: flex;
}

.flex-row-center {
  justify-content: center;
  flex: 1;
  flex-direction: row;
  display: flex;
}

.flex-row-start {
  justify-content: flex-start !important;
  flex: 1 !important;
  flex-direction: row !important;
  display: flex !important;
}

.flex-row-end {
  justify-content: flex-end !important;
  flex: 1 !important;
  flex-direction: row !important;
  display: flex !important;
}

.flex-column {
  justify-content: space-between;
  flex: 1 !important;
  flex-direction: column !important;
  display: flex !important;
}

.flex-column-0 {
  justify-content: space-between;
  flex: 0;
  flex-direction: column !important;
  display: flex !important;
}

.flex-column-0p4 {
  justify-content: space-between;
  flex: 0.4;
  flex-direction: column !important;
  display: flex !important;
}

.flex-column-start {
  justify-content: flex-start !important;
  flex: 1 !important;
  flex-direction: column !important;
  display: flex !important;
}

mat-form-field {
  display: flex !important;
  flex: 1 1 auto !important;
}

::ng-deep .custom-paginator-tooltip .mat-tooltip {
  margin-left: -16px !important;
  margin-top: 12px !important;
  position: fixed !important;
  background: var(--colore-testo-toolbar);
  white-space: pre-wrap;
  max-width: 300px; /* Imposta la larghezza massima del tooltip */
}
/* Definisci l'animazione 'fadeOut' */
@keyframes fadeOut {
  0% {
    opacity: 1 !important;
  }
  100% {
    opacity: 0 !important;
  }
}

.flex {
  display: flex;
}

.stretch {
  align-items: stretch;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.double-baloon {
  // filter: invert(36%) sepia(50%) saturate(1316%) hue-rotate(153deg);
  fill: red;
  color: red;
}

.colore-riferimento {
  color: var(--colore-riferimento) !important;
}

.condivisione-inattiva {
  color: rgb(190, 190, 190);
}

.condivisione-attiva {
  color: rgb(255, 255, 255);
}

.comunicazione-segnalante-inattiva {
  color: rgb(190, 190, 190);
}

.comunicazione-segnalante-attiva {
  color: rgb(255, 255, 255);
}

.visibilita-segnalante-disabilitata {
  color: rgb(190, 190, 190);
}

.visibilita-segnalante-abilitata {
  color: rgb(255, 255, 255);
}

.visibilita-segnalati-disabilitata {
  color: rgb(190, 190, 190);
}

.visibilita-segnalati-abilitata {
  color: rgb(255, 255, 255);
}

.ascolto-audio-non-in-chiaro {
  color: rgb(190, 190, 190);
}

.servizio-attivo {
  color: rgb(255, 255, 255);
}

.servizio-non-attivo {
  color: rgb(190, 190, 190);
}

.ascolto-audio-in-chiaro {
  color: rgb(255, 255, 255);
}

.lavorazione-inattiva {
  color: rgb(190, 190, 190);
}

.lavorazione-attiva {
  color: rgb(255, 255, 255);
}

.lavorazione-non-modificabile {
  color: rgb(190, 190, 190);
}

.lavorazione-modificabile {
  color: rgb(255, 255, 255);
}

.colore-testo-toolbar {
  color: var(--colore-testo-toolbar) !important;
  background-color: var(--colore-background-toolbar) !important;
}

.mat-form-field-wrapper {
  display: flex !important;
  flex: 1 1 100% !important;
  // width: 24px!important;
}

.mat-paginator-page-size-select .mat-form-field-wrapper {
  width: 24px !important;
}

.mat-menu-panel {
  max-width: 600px !important;
}

.list-item-configurazione {
  margin-bottom: 50px;
  margin-top: 50px;
  height: auto !important;
}

.flex-gap-10 {
  gap: 10px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-30 {
  gap: 30px;
}

.flex-gap-50 {
  gap: 50px;
}

.flex-100 {
  flex: 1 1 100%;
}

.flex-40 {
  flex: 0 0 40%;
}

.flex-55 {
  flex: 0 0 55%;
}

.flex-30 {
  flex: 0 0 30%;
}

.flex-15 {
  flex: 0 0 15%;
}

.flex-10 {
  flex: 0 0 10%;
}

.flex-20 {
  flex: 0 0 20%;
}

.flex-0 {
  flex: 0 0 0%;
}

.flex-space-around-center {
  justify-content: space-around;
  align-items: center;
}

.flex-space-between-center {
  justify-content: space-between;
  align-items: center;
}

.flex-space-evenly-center {
  justify-content: space-evenly;
  align-items: center;
}

.flex-5 {
  flex: 0 0 5%;
  max-width: 5%;
}

.flex-10 {
  flex: 0 0 10%;
  max-width: 10%;
}

.flex-15 {
  flex: 0 0 15%;
  max-width: 15%;
}

.flex-20 {
  flex: 0 0 20%;
  max-width: 20%;
}

.flex-25 {
  flex: 0 0 25%;
  max-width: 25%;
}

.flex-30 {
  flex: 0 0 30%;
  max-width: 30%;
}

.flex-35 {
  flex: 0 0 35%;
  max-width: 35%;
}

.flex-40 {
  flex: 0 0 40%;
  max-width: 40%;
}

.flex-45 {
  flex: 0 0 45%;
  max-width: 45%;
}

.flex-50 {
  flex: 0 0 50%;
  max-width: 50%;
}

.flex-55 {
  flex: 0 0 55%;
  max-width: 55%;
}

.flex-60 {
  flex: 0 0 60%;
  max-width: 60%;
}

.flex-65 {
  flex: 0 0 65%;
  max-width: 65%;
}

.flex-70 {
  flex: 0 0 70%;
  max-width: 70%;
}

.flex-75 {
  flex: 0 0 75%;
  max-width: 75%;
}

.flex-80 {
  flex: 0 0 80%;
  max-width: 80%;
}

.flex-85 {
  flex: 0 0 85%;
  max-width: 85%;
}

.flex-90 {
  flex: 0 0 90%;
  max-width: 90%;
}

.flex-95 {
  flex: 0 0 95%;
  max-width: 95%;
}

.flex-100 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Flexbox */
.flex-fill {
  flex: 1;
}

.flex-fill-sm {
  flex: 1;
}

.flex-fill-gt-xs {
  flex: 1;
}

.flex-fill-xs {
  flex: 1;
}

.flex-fill-xs-0 {
  flex: 0;
}

.flex-xs {
  flex: 1;
}

.flex-center-stretch {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.flex-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-xs {
  display: none;
}

.barra-principale {
  height: 96px !important;
}

/* Flex Gap */
.flex-gap-0 {
  gap: 0;
}

.flex-gap-xs-10 {
  gap: 10px;
}

/* Flex Grow and Shrink (You may need to adjust these values to fit your design) */
.flex-md-850 {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 850px;
}

.expander {
  cursor: pointer !important;
  position: absolute !important;
  top: 48px !important;
  left: calc(100% + 10px) !important;
  white-space: nowrap !important;
  height: 50% !important;
}


/* Styles for smallest screens (<= 420px) */
@media only screen and (max-width: 420px) {
  body,
  body [class^='mat-'],
  body [class*=' mat-'],
  input,
  .mat-cell,
  .mat-input-element,
  .mat-expansion-panel .mat-expansion-panel-content,
  .mat-paginator,
  .mat-button,
  .mat-button .mat-fab,
  .mat-flat-button,
  .mat-icon-button,
  .mat-mini-fab,
  .mat-raised-button,
  .mat-stroked-button {
    margin: 0;
  }
  .allega {
    font-size: 15px;
  }
  .material-icons {
    width: 48px;
    height: 48px;
  }
  .width-half {
    width: 100% !important;
  }
  .width-half-start {
    width: 80% !important;
  }
  .width-half-end {
    width: 20% !important;
  }
  .min-width {
    width: 100% !important;
  }

  .hidden-xs {
    display: flex !important;
  }
  .flex-fill-xs-0 {
    flex: 1 !important;
  }
  .flex-fill-gt-xs {
    flex: 0 !important;
  }
  .flex-lang-selector-pos {
    justify-content: flex-start !important;
  }
  .flex-gap-xs-10 {
    gap: 10px !important;
  }
  .desktop-table {
    display: none !important;
  }
  .mobile-list {
    display: block !important;
  }
  .barra-principale {
    height: 200px !important;
  }
}

/* Styles for screens between 421px and 599px */
@media only screen and (min-width: 421px) and (max-width: 599px) {
  .altezza-toolbar-landing {
    height: 200px !important;
  }
  .barra-principale {
    height: 200px !important;
  }
  .mat-cell:nth-child(n + 3),
  .mat-header-cell:nth-child(n + 3) {
    display: none !important;
  }

  .desktop-table {
    display: none !important;
  }
  .mobile-list {
    display: block !important;
  }
}
.custom-warning {
  background-color: rgba(255, 255, 0, 0.8);
  color: #333;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  width: auto;
  text-align: center;
}

@media only screen and (max-width: 599px) {
  .barra-principale {
    height: 200px !important;
  }
  .altezza-toolbar-landing {
    height: 200px !important;
  }
  .flex-fill-gt-xs {
    flex: 0 !important;
  }
  .flex-inner-toolbar {
    margin-top: 10px !important;
    display: flex;
    flex: 1;
  }
  .logo-container {
    order: 2;
    margin: auto !important;
  }
  .language-selector-container {
    order: 1;
    margin-top: 100px;
  }
  .language-selector-container-inner {
    margin-top: 100px;
  }
  html {
    margin: 2px;
  }
}

@media only screen and (min-width: 600px) {
  .flex-item {
    flex: 1 1 100%;
  }

  .my-flex-element {
    flex: 1 1 auto; /* Cresce per riempire lo spazio disponibile */
  }

  .altezza-toolbar-landing {
    height: 94px !important;
  }
  .barra-principale {
    height: 96px !important;
  }
  flex-lang-selector-pos {
    flex: 1 1 0% !important;
    justify-content: flex-end !important;
    flex-direction: row !important;
  }
  .logo-container {
    order: 1;
    margin-right: auto !important;
  }
  .language-selector-container {
    order: 2;
  }
  html {
    margin: 0px;
  }
}

/* Styles for medium screens (600px - 1279px) */
@media only screen and (min-width: 600px) and (max-width: 1279px) {
  .flex-separator {
    flex: 1 1 0%;
  }
  .altezza-toolbar-landing {
    height: 94px !important;
  }
  .flex-lang-selector-pos {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .flex-inner-toolbar {
    display: flex !important;
    flex: 1 !important;
    flex-direction: row !important;
  }
  .flex-gt-md-1070 {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: 850px !important;
  }
  .desktop-table {
    display: none !important;
  }
  .mobile-list {
    display: block !important;
  }
}

/* Styles for large screens (1280px - 1599px) */
@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .flex-separator {
    flex: 1 1 0% !important;
  }
  .altezza-toolbar-landing {
    height: 94px !important;
  }
  .flex-inner-toolbar {
    display: flex !important;
    flex: 1 !important;
    flex-direction: row !important;
  }
  .flex-lang-selector-pos {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }
  .flex-gt-md-1070 {
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    flex-basis: 1070px !important;
  }
  .desktop-table {
    display: none !important;
  }
  .mobile-list {
    display: block !important;
  }
}

/* Styles for extra large screens (>= 1600px) */
@media only screen and (min-width: 1600px) {
  .flex-item {
    flex: 1 0 1590px;
    max-width: 1590px; /* Per assicurarsi che non superi i 1070px */
  }
  .flex-separator {
    flex: 1 1 0% !important;
  }
  .altezza-toolbar-landing {
    height: 94px !important;
  }
  .flex-lang-selector-pos {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }
  .flex-inner-toolbar {
    display: flex !important;
    flex: 1 !important;
    flex-direction: row !important;
  }
  .flex-gt-md-1070 {
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    flex-basis: 1070px !important;
  }
  .desktop-table {
    display: block !important;
  }
  .mobile-list {
    display: none !important;
  }
}

.cdk-overlay-pane {
  position: fixed;
}

.cdk-global-scrollblock {
  overflow: auto !important;
  position: relative !important;
}

.logo-cliente {
  height: var(--altezza-logo-cliente);
}

.logo-secure {
  height: var(--altezza-logo-secure);
}

.logo-centrato {
  display: block;
  margin: auto;
}

.mat-expansion-panel .mat-expansion-panel {
  background: var(--colore-background-chiaro);
  color: var(--colore-testo);
  margin-bottom: 24px;
  box-shadow: none !important;
  border-radius: 24px;
}

.bold {
  font-weight: bold;
}

.centrato {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalita-abilitata {
  background-color: var(--colore-background-chiaro) !important;
}

.mostra-parola-in-chiaro {
  padding: 16px;
}

mat-option.possiede-autorizzazioni-pendenti > span.mat-option-text {
  color: red !important;
}

.font-smaller {
  font-size: smaller;
}

/* Stile di default per dispositivi extra-small */
.my-flex-element {
  flex: 1 1 auto; /* Non cresce, non si restringe, ha una dimensione automatica (di solito 0 se non ha contenuto) */
  overflow: hidden; /* Nascondi eventuali contenuti */
}

.flex-container {
  display: flex;
  flex-direction: row; /* corrisponde a fxLayout="row" */
  gap: 0; /* corrisponde a fxLayoutGap="0" */
}
@media (max-width: 1280px) {
  /* Stili per lt-md (less than md) */
  .flex-container {
    flex-direction: column; /* corrisponde a fxLayout.lt-md="column" */
  }
  .sezione-1-landing {
    display: flex;
    flex-direction: column;
  }
  .sezione-2-landing {
    display: flex;
    flex-direction: column;
  }
  .sezione-faq {
    margin: auto !important;
  }
  .dialog-visualizzatore {
    font-size: small;
    margin: 0;
    padding: 0;
  }
  .altezza-visualizzatore {
    height: 100vh !important;
  }
  .titolo-verifica-email {
    justify-content: center !important;
    align-items: center !important;
    height: 64px !important;
  }
  .pannello-grafico {
    display: flex;
    flex-direction: column;
  }
}

.sezione-video {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 60px;
  padding-left: 24px;
  padding-bottom: 12px;
}

@media (min-width: 1279px) {
  /* Stili per gt-md (more than md) */
  .sezione-1-landing {
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
  .sezione-2-landing {
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
  .altezza-visualizzatore {
    height: 90vh !important;
  }
  .toolbar-landing-extra {
    height: 64px !important;
  }
  .pannello-grafico {
    display: flex;
    flex-direction: row;
  }
}

/* Stili per lt-sm (less than sm) */
@media (max-width: 599px) {
  .titoli-sezione-video {
    font-family: 'Montserrat' !important;
    font-size: 28px;
    color: #444444;
    font-weight: 800;
  }
  .testo-avviso-segnalazione {
    font-size: 0.75em;
  }
  .toolbar-landing-extra {
    justify-content: center !important;
    align-items: end !important;
    height: 128px !important;
  }
  .distanziatore-selettore-lingua {
    margin-left: 4px;
  }
  .logo-dimensione-toolbar {
    width: 180px !important;
  }
  .container-chat-manager {
    justify-content: space-between;
    flex: 1 !important;
    flex-direction: column !important;
    display: flex !important;
  }
  .chat-manager-item-1 {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: column !important;
    display: flex !important;
  }
  .chat-manager-item-2 {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: column !important;
    display: flex !important;
  }
  /* CHAT */
  app-chat-manager .alternate-theme {
    margin: 4px !important;
  }

  .sotto-testata-dettaglio-segnalazione {
    justify-content: space-between;
    flex: 1 !important;
    flex-direction: column !important;
    display: flex !important;
  }

  .column-row-switch {
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
    display: flex;
    margin:auto;
  }

  .language-list {
    position: absolute !important;
    top: 48px !important;
    margin-left: 32px;
    left: calc(100% + 10px) !important;
    white-space: nowrap !important;
    height: 50% !important;
    overflow-x: hidden !important;
  }
  .login-toolbar {
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
    display: flex;
    height: 96px !important;
  }
}

/* Stili per gt-sm (more than sm) */
@media (min-width: 600px) {
  .titoli-sezione-video {
    font-family: 'Montserrat' !important;
    font-size: 34px;
    color: #444444;
    font-weight: 800;
  }
  .sotto-toolbar-landing {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 60px;
    padding-left: 24px;
    padding-bottom: 12px;
  }
  .distanziatore-selettore-lingua {
    margin-left: 48px;
  }
  .container-chat-manager {
    justify-content: space-between;
    flex: 1 !important;
    flex-direction: row !important;
    display: flex !important;
  }
  .chat-manager-item-1 {
    flex: 0 0 40%;
    max-width: 40%;
    justify-content: space-between;
    flex-direction: column !important;
    display: flex !important;
  }
  .chat-manager-item-2 {
    flex: 0 0 55%;
    max-width: 55%;
    justify-content: space-between;
    flex-direction: column !important;
    display: flex !important;
  }

  /* CHAT */
  app-chat-manager .alternate-theme {
    margin: 48px !important;
  }

  .sotto-testata-dettaglio-segnalazione {
    justify-content: space-between;
    flex: 1 !important;
    flex-direction: row !important;
    display: flex !important;
  }
  .column-row-switch {
    justify-content: space-between;
    flex: 1;
    flex-direction: row;
    display: flex;
  }
  .language-list {
    position: absolute !important;
    top: 44px !important;
    margin-left: 14px;
    left: calc(100% + 10px) !important;
    white-space: nowrap !important;
    height: 50% !important;
    overflow-x: hidden !important;
  }
  .login-toolbar {
    justify-content: space-between;
    flex: 1;
    flex-direction: row;
    display: flex;
    height: 96px !important;
  }
}

.div-flottante {
  display: flex;
  align-items: center; /* Allinea verticalmente i figli al centro */
}

.div-flex-element {
  flex: 1 1 0%; /* Questo fa in modo che entrambi i div (primo e terzo) abbiano la stessa larghezza */
}

.div-flex-item {
  flex: 2; /* O qualunque altro valore maggiore di 1 per fare sì che il div centrale cresca di più rispetto agli altri due div */
  display: flex;
  align-items: center; /* Allinea verticalmente i figli al centro */
  justify-content: space-between; /* Distribuisce lo spazio tra i figli del div */
}

/* Stili aggiuntivi per fxLayoutGap e fxLayoutAlign - potresti doverli adattare in base al tuo design */
.div-flex-item > div:first-child {
  display: flex;
  align-items: center;
  gap: 8px; /* corrisponde a fxLayoutGap="8" */
}

.div-flex-item > div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* corrisponde a fxLayoutAlign="end center" */
}

.flex-item {
  /* Stili di base per xs e sm */
  flex: 1 1 100%; /* Cresce e si restringe liberamente, ma prende l'intera larghezza disponibile (100%) */
}

@media (min-width: 960px) and (max-width: 1279px) {
  /* md styles */
  .flex-item {
    flex: 1 0 850px;
    max-width: 850px; /* Per assicurarsi che non superi i 850px */
  }
}

@media (min-width: 1280px) and (max-width: 1599px) {
  /* gt-md styles */
  .flex-item {
    flex: 1 0 1070px;
    max-width: 1070px; /* Per assicurarsi che non superi i 1070px */
  }
}

@-webkit-keyframes highlightFade {
  from {
    background-color: yellow !important;
  }
  to {
    background-color: transparent !important;
  }
}

.focused .mat-sort-header-content {
  background-color: yellow !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-start-13 {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex: 0 0 13%;
  text-align: start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-flex-start {
  align-content: flex-start;
}

.align-content-flex-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-right {
  justify-content: right;
}

.width-20 {
  width: 20%;
}
.height-94 {
  height: 94px;
}

.margin-left-4{
  margin-left: 4px!important;
}

.wrap-text {
  white-space: normal;
  overflow-wrap: break-word;
}

.testo-label-scalabile {
  font-size: clamp(14px, 2vw, 18px)!important;
}

.mat-form-field-empty {
  font-size: clamp(14px, 2vw, 18px)!important;
  //top:-3px!important;
}

.mat-select {
  height:26px;
}
.no-scroll {
  overflow: hidden;
}
.testo-rosso {
  color: red!important;
}

.angular-editor-placeholder {
  font-size: clamp(14px, 2vw, 20px)!important;
}

.small-font {
  font-size: 10px!important;
}

.messaggio-letto {
  color: #0088ff!important;
}

.colore-background {
  color: var(--colore-background);
}
.larger-font {
  font-size: large!important;
}

.justify-content-end {
  justify-content: end;
}

.colore-background {
  color: var(--colore-background)!important;  
}
/* * {
  outline: 1px solid red !important;
}
 */
